import { nanoid } from 'nanoid';
import contentData from './content-data.json';
// HEAD DATA
export const headData = {
  title: '無料で使える、商用利用可能な効果音や音楽', // e.g: 'Name | Developer'
  lang: 'jp', // e.g: en, es, fr, jp
  description:
    '動画制作やソフトウェア開発など、様々な用途で無料で使える商用利用可能な効果音や音楽をまとめています。YouTubeなどの動画制作をはじめ、様々な用途にお役立てください。', // e.g: Welcome to my website
  keyword: '商用利用可能な音楽,無料,動画制作,ポッドキャスト,音楽',
  siteUrl: 'https://sound-factory.net',
  ogpUrl: 'ogp.png',
};

// HERO DATA
export const heroData = {
  title: '動画作成時に自由に使える音楽をお探しの方へ',
  subTitle: 'こちらで扱っている音楽はすべて無料・商用利用可能です',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.png',
  paragraphOne: '',
  paragraphTwo: '',
  paragraphThree: '',
  resume: 'https://shinshin86.com', // if no resume, the button will not show up
};

// PROJECTS DATA
export const { projectsData } = contentData;

// CONTACT DATA(Not used)
/*
export const contactData = {
  title: '音楽のリクエストを送る',
  text1: '利用したい音楽がここにない場合、リクエストを送ることで音楽が追加されるかもしれません。',
  text2:
    'ただし、あくまで余暇を利用して作成を行うため、作曲のための時間がなかなか取れない場合、お時間をいただきます。そのため、期待せずにお待ちいただけたらと思います。また、難しいリクエストやイメージがつかないものについても対応しませんので、その点ご了承ください。',
  text3: '音楽のリクエストフォームにはGoogleフォームを利用しています。',
};
*/
export const contactData = {};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'twitter',
      url: 'https://twitter.com/shinshin86',
    },
  ],
};
